<template>
  <div>
    <div class="newSearch">
      <b-container>
        <b-jumbotron :header="'Search \'' + filter + '\''">
          <b-row>
            <b-col cols lg="6" sm="12">
              <b-form-input v-on:keyup.enter="onEnter" class='seachInput' v-model="text" placeholder="Search"></b-form-input>
            </b-col>
            <b-col cols lg="6" sm="12">
              <b-link to='/news'>
                <div data-v-225d8f26="" class="backTonews">Back to News</div>
              </b-link>
            </b-col>
          </b-row>
        </b-jumbotron>
      </b-container>
    </div>

    <template v-if="loading !== true">
      <div class="loader"></div>
    </template>

    <b-container v-if="loading === true">
      <b-table
        v-if="filter"
        :items="allposts"
        :fields="fields"
        stacked="md"
        show-empty
        small
        :filter="filter"
      >
        <template #cell(better_featured_image)="row">
          <b-row>
            <template v-if="isMobile(true)">
              <b-col lg='12'>
                <b-img class='imgMob' :src="row.item.better_featured_image.source_url"></b-img>
              </b-col>
              <b-col lg='12'>
                <b-nav-item :to="'/' + row.item.slug" v-if="develop === 'apga'">
                  <h2 v-html="row.item.title.rendered"></h2>
                  <h6 class="itemDate">Published: {{row.item.date.replace(/-/g, "/",).replace(/\T.*/,'')}}</h6>
                </b-nav-item>
                <b-nav-item :to="'/adt/' + row.item.slug" v-if="develop === 'adt'">
                  <h2 v-html="row.item.title.rendered"></h2>
                </b-nav-item>
                <span v-html="row.item.excerpt.rendered"></span> 
              </b-col>
            </template>
            <template v-else>
              <b-col lg='4'>
                <b-img class='img' :src="row.item.better_featured_image.source_url"></b-img>
              </b-col>
              <b-col lg='8'>
                <b-nav-item :to="'/' + row.item.slug" v-if="develop === 'apga'">
                  <h2 v-html="row.item.title.rendered"></h2>
                  <h6 class="itemDate">Published: {{row.item.date.replace(/-/g, "/",).replace(/\T.*/,'')}}</h6>
                </b-nav-item>
                <b-nav-item :to="'/adt/' + row.item.slug" v-if="develop === 'adt'">
                  <h2 v-html="row.item.title.rendered"></h2>
                </b-nav-item>
                <span v-html="row.item.excerpt.rendered"></span> 
              </b-col>
            </template>
          </b-row>
        </template
      ></b-table>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "search",
  props: ["develop"],
  data() {
    return {
      allposts: [],
      filter: this.$route.query.search,
      fields: [{ key: "better_featured_image", label: "" }],
      filterOn: [],
      text:this.$route.query.search,
      loading:''
    };
  },
  computed: {
    setDev: function() {
      if (this.develop === "apga")
        return process.env.VUE_APP_WP_ASIAN_TOUR_API_URL+"wp/v2/posts?search=" + this.$route.query.search + "&_embed?randomadd=" + new Date().getTime();
      else
        return process.env.VUE_APP_WP_ADT_API_URL+"wp/v2/posts?search=" + this.$route.query.search + "&_embed?randomadd=" + new Date().getTime();
    },
    // dateTime:function(){
    //   var dateOG = this.allposts[0].date.split('T')
    //   var splitDate = dateOG[0].split('-')
    //   var newDate = splitDate[2]+ '/' + splitDate[1] + '/' + splitDate[0]
    //   return newDate

    // }
  },
  mounted() {
    setTimeout(() => {
    axios
      .get(this.setDev + "?randomadd=" + new Date().getTime())
      .then(response => (
        this.allposts = response.data,
        this.loading = true
      ));},1000)
  },
  methods: {
    onEnter: function() {
       location.href = '/search?search='+ this.text + '&develop=' + this.develop;
    },
    isMobile() {
      if( screen.width <= 500 ) {
        return true;
      }
      else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.backTonews {
  width: 324px;
  height: 43px;
  background-color: #0A3F7F;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 3.1;
  margin: auto;
  /* margin-top: 50px; */
  float: right;
}
.backTonews:hover {
  width: 324px;
  height: 43px;
  background-color: #5897E6;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 3.1;
  margin: auto;
  /* margin-top: 50px; */
  float: right;
}
a:hover {
  color: #0056b3;
  text-decoration: none;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
h6.itemDate {
  color:#263056;
  font-size: 11pt;
}
.sIcon {
  color: white !important;
  background-color: #0b3f7e!important;
  padding: 5px 11px;
  border-radius: 0px 5px 5px 0px !important;
}
::v-deep thead > tr {
  display: none;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #263056; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
h2 {
  font-size: 30px;
}
::v-deep p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 14px!important;
}
::v-deep .jumbotron {
  padding-left: 0;
  /* background-color: white!important; */
}
li {
  list-style-type: none;
}
.nav-link {
  padding: 0.5em 0;
}
::v-deep table {
  margin-top: 75px;
}
.newSearch {
  background-color: #eee;
  /* margin-top: 75px; */
}
::v-deep .display-3 {
  font-size: 1.3rem;
  color: #133f7a;
  font-weight: 500;
  margin-bottom: 30px;
}
.seachInput{
  border-radius: 0;
  border: 0px solid #c7c7cc;
  height: 43px;
  font-size: 16px;
  width: 100%;
}
.sIcon {
  color: white !important;
  background-color: #263056;
  padding: 5px 11px;
  border-radius: 5px;
  margin-top: -2px;
  height: 37px;
}
.img{
  width: 60%;
}
::v-deep td{
  padding:30px 0.3rem;
}
::v-deep h2 {
  font-size: 1.3rem;
  color: #133f7a;
}
h6.itemDate {
  color: #000000;
  font-size: 14px;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  ::v-deep .backTonews {
    width: 100%;
    height: 43px;
    background-color: #0A3F7F;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 3.1;
    margin: auto;
    /* margin-top: 50px; */
    float: none;
    margin-top: 25px;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > [data-label] > div {
    display: inline-block;
    width: 100%!important;
    padding: 0!important;
    margin: 0;
  }
  .imgMob {
    width: 100%;
  }
  ::v-deep h2 {
    font-size: 1.2rem;
    color: #133f7a;
  }
  h6.itemDate {
    color: #000000;
    font-size: 11px;
    font-weight: 700;
  }
  ::v-deep p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 12px!important;
  }
}
</style>
